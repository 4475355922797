import { useEffect, useState } from "react";

import { ProgressBar } from "react-step-progress-bar";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getInterventionDocument,
  getInterventionDomains,
  postIntervention,
  uploadInterventionDoc,
} from "./InterventionApi";
import { useLocation, useNavigate } from "react-router-dom";

import { faArrowLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import bookImg from "../../../assets/images/book.svg";
import InterventionMarking from "./InterventionMarking";
import InterventionMarkLevels from "./InterventionMarkLevels";
import InterventionPlan from "./InterventionPlan";
import Modal from "../../modal/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";

const InterventionDomain = () => {
  const location = useLocation();

  const userId = JSON.parse(localStorage.getItem("id"));
  const [interventionDomainList, setInterventionDomainList] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [domainCategoryId, setDomainCategoryId] = useState();
  const [domainMasterId, setDomainMasterId] = useState();
  const [markingOpen, setMarkingOpen] = useState(false);
  const studentData = location.state.data;
  const navigate = useNavigate();
  const [openMarksInfo, setOpenMarksInfo] = useState(false);
  const [openInterventionPlan, setOpenInterventionPlan] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);
  const parse = require("html-react-parser");
  const [subDomainName, setSubDomainName] = useState();
  const [refresh, setRefresh] = useState(false);
  const [readPlanData, setReadPlanData] = useState();
  const [serverErrorModal, setServerErrorModal] = useState(false);

  const [showInterventionDocModel, setShowInterventionDocModel] =
    useState(false);
  const [interventionDocument, setinterventionDocument] = useState([]);

  const [interventionStart, setInterventionStart] = useState({
    userID: userId,
    studentID: studentData?.studentId,
    domainCategoryID: 0,
    domainMasterID: 0,
    classroomObservationID: 0,
    inputLevelID: 0,
  });

  const getTranslateText = useSelector(getTranslatedTextSelector);

  const interventionDomain = () => {
    getInterventionDomains(userId, studentData?.studentId).then((response) => {
      if (!response) {
        setServerErrorModal(true);
        return;
      }

      setInterventionDomainList(response.data.response);
      setRefresh(false);
    });
  };

  useEffect(() => {
    interventionDomain(userId, studentData.studentId);
  }, [refresh]);

  // Post Intervention Api //
  const statusChange = (data, content) => {
    const updatedInterventionData = {
      ...interventionStart,
      domainCategoryID: data.domainCategoryId,
      domainMasterID: content.domainId,
    };
    postIntervention(updatedInterventionData).then((response) => {});
  };

  // Toggle Dropdown //
  const toggledropdown = (index, data) => {
    setOpenDropdown(true);

    setDomainCategoryId();
  };

  // Open Marking //
  const openMarking = (data, content) => {
    statusChange(data, content);
    setMarkingOpen(true);
    setDomainMasterId(content.domainId);
    setSubDomainName(content.domainName);
  };

  // Upload Document //
  const uploadDoc = (e) => {
    const imageFile = e.target.files[0];
    let formData = new FormData();
    formData.append("UserID", userId);
    formData.append("StudentID", studentData?.studentId);
    formData.append("DocumentTypeID", 1);
    formData.append("DocumentNo", "0");
    formData.append("FilePath", 0);
    formData.append("filePath", imageFile);

    uploadInterventionDoc(formData).then((response) => {
      if (response?.data?.errorCode === "409") {
        toast.error("You can upload upto 3 documents..!");
      } else {
        toast.success("Document Uploaded Successfully");
      }
      e.target.value = "";
    });
  };

  const viewInterventionDoc = () => {
    getInterventionDocument(userId, studentData?.studentId).then((response) => {
      setinterventionDocument(response.data?.response);
    });
  };

  const onClickViewDoc = () => {
    viewInterventionDoc();
    setShowInterventionDocModel(true);
  };

  return (
    <>
      <div className="bg-[#F4F4FA] p-4 md:p-8 h-[calc(100%_-_8%)]">
        {openMarksInfo === true ? (
          <InterventionMarkLevels setOpenMarksInfo={setOpenMarksInfo} />
        ) : openInterventionPlan === true ? (
          <InterventionPlan
            setOpenInterventionPlan={setOpenInterventionPlan}
            studentData={studentData}
            readPlanData={readPlanData}
          />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: "#000000" }}
                  size="xl"
                  onClick={() => {
                    if (markingOpen === false) {
                      navigate(-1);
                    } else {
                      setMarkingOpen(false);
                      setRefresh(true);
                    }
                  }}
                  className="mr-2 cursor-pointer"
                />
                <div className="flex flex-row items-center mb-[20px] pt-[20px]">
                  <img
                    // src="../Images/student-screening-pic.png"
                    src={
                      studentData.studentPhotoUrl?.toLowerCase() !== "na" &&
                      studentData.studentPhotoUrl !== "undefined"
                        ? studentData.studentPhotoUrl
                        : "../Images/icon-student-thumbnail.png"
                    }
                    alt=""
                    className="border-[#fa2229] rounded-[50%] border-2 w-[40px] h-[40px]"
                  />
                  <div className="flex flex-row justify-between w-full">
                    <div className="flex flex-col text-left pl-[5px]">
                      <p className="mb-[2px] font-semibold text-[16px]">
                        {studentData.studentName}
                      </p>
                      <p className="text-[#848BAA] text-[12px]">
                        {studentData.gradeName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={bookImg}
                className="w-[20px] cursor-pointer"
                onClick={() => {
                  setInstructionModal(true);
                }}
                alt=""
              />
            </div>
            <div className="bg-[#fff] py-6 md:px-8 px-4 rounded-[30px] h-[calc(100%_-_20%)] overflow-y-scroll no-scrollbar">
              {markingOpen === false ? (
                <div className="flex flex-col justify-between h-[100%]">
                  <div>
                    <p className="text-[#848BAA] font-normal text-[14px] text-left">
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) =>
                              item?.pageKey ===
                              "intervention_domainwise_top_instruction"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) =>
                                item?.pageKey ===
                                "intervention_domainwise_top_instruction"
                            )[0]?.keyValue
                          : "The following Domains and the SubDomains of the child are affected. Kindly select the Domain and then the SubDomain to read the concerns found in the child."
                        : "The following Domains and the SubDomains of the child are affected. Kindly select the Domain and then the SubDomain to read the concerns found in the child."}
                    </p>
                    <div>
                      {interventionDomainList?.map((data, index) => (
                        <>
                          <div
                            className="shadow-[0px_8px_24px_#464C881A] rounded-[10px] flex justify-between items-center py-4 px-4 my-4 cursor-pointer"
                            onClick={() => {
                              toggledropdown(data);
                              setDomainCategoryId(data.domainCategoryId);
                            }}
                          >
                            {/* Mobile view */}
                            <div className="sm:hidden">
                              <h4 className="w-[20%] text-left w-3/3">
                                {data.domainCategoryName}
                              </h4>
                              <div className="w-6/6 ">
                                <div className="flex justify-between mb-1">
                                  <p className="text-[#848BAA] font-normal text-left text-[14px]">
                                    Progress achieved
                                  </p>
                                  <p className="text-[#3146B2] font-semibold text-[14px]">
                                    {data.progressDomainCategory}%
                                  </p>
                                </div>
                                <ProgressBar
                                  percent={data.progressDomainCategory}
                                  filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                                  //   width={`${mobileOn === true ? "70px" : "200px"}`}
                                  width={`250px`}
                                  //   position={answerSheet.screeningQuestionOption.length}
                                  //   index={answerSheet.screeningQuestionOption.length}
                                />
                              </div>
                            </div>
                            <h4 className="w-[20%] text-left w-1/3 hidden md:block">
                              {data.domainCategoryName}
                            </h4>
                            <div className="w-1/3 hidden md:block">
                              <div className="flex justify-between mb-1">
                                <p className="text-[#848BAA] font-normal text-[14px]">
                                  Progress achieved
                                </p>
                                <p className="text-[#3146B2] font-semibold text-[14px]">
                                  {data?.progressDomainCategory}%
                                </p>
                              </div>
                              <ProgressBar
                                percent={data.progressDomainCategory}
                                filledBackground="linear-gradient(to right, #111E5B, #274CC3)"
                                //   width={`${mobileOn === true ? "70px" : "200px"}`}
                                width={`100%`}
                                //   position={answerSheet.screeningQuestionOption.length}
                                //   index={answerSheet.screeningQuestionOption.length}
                              />
                            </div>
                            <div className="w-1/3 text-right">
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                style={{ color: "#858cab" }}
                                rotation={
                                  domainCategoryId === data.domainCategoryId
                                    ? 90
                                    : 0
                                }
                                className="transition-all	"
                              />
                            </div>
                          </div>
                          {domainCategoryId === data.domainCategoryId ? (
                            <div>
                              {data.observationStatements.map((content) => (
                                <div
                                  className="bg-[#ECF1FF] flex justify-between items-center rounded-[10px] py-2 px-4 my-4 sm:ml-14 ml-4 transition-all	cursor-pointer"
                                  onClick={() => openMarking(data, content)}
                                >
                                  <div className="sm:hidden">
                                    <h5 className="text-[#101942] text-[16px] font-medium text-left w-6/6">
                                      {content.domainName}
                                    </h5>
                                    <p className="text-[#848BAA] text-[14px] w-6/6 text-left">
                                      Progress achieved{" "}
                                      {content.progressDomainMaster}%
                                    </p>
                                  </div>
                                  <h5 className="text-[#101942] text-[16px] font-medium text-left w-2/6 hidden sm:block">
                                    {content.domainName}
                                  </h5>
                                  <p className="text-[#848BAA] text-[14px] w-2/6 hidden sm:block">
                                    Progress achieved{" "}
                                    {content.progressDomainMaster.toFixed(2)}%
                                  </p>
                                  <div className="w-3/6 text-right">
                                    <FontAwesomeIcon
                                      icon={faChevronRight}
                                      style={{ color: "#858cab" }}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="bg-[#223384] text-[#fff] rounded-[10px] p-2">
                    <p className="text-left">
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) =>
                              item?.pageKey ===
                              "intervention_domainwise_upload_instruction"
                          )[0]?.keyValue
                          ? getTranslateText?.data?.filter(
                              (item) =>
                                item?.pageKey ===
                                "intervention_domainwise_upload_instruction"
                            )[0]?.keyValue
                          : "We invite you to submit text documents, images, or videos, showcasing your educational activities and student progress to help us inspire others, and recognize your dedication."
                        : "We invite you to submit text documents, images, or videos, showcasing your educational activities and student progress to help us inspire others, and recognize your dedication."}
                    </p>
                    <div className="flex items-center justify-end gap-10">
                      <a
                        href={onClickViewDoc}
                        class="text-[#F9B239] font-semibold underline cursor-pointer"
                        onClick={onClickViewDoc}
                      >
                        {getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item.pageKey === "key_view_files"
                            )[0]?.keyValue
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "key_view_files"
                              )[0]?.keyValue
                            : "View Files"
                          : "View Files"}
                      </a>
                      <label className="">
                        <input
                          type={"file"}
                          className="hidden"
                          onChange={uploadDoc}
                        />
                        <p className="text-[#F9B239] font-semibold underline cursor-pointer">
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item.pageKey === "key_upload_doc"
                              )[0]?.keyValue
                              ? getTranslateText?.data?.filter(
                                  (item) => item?.pageKey === "key_upload_doc"
                                )[0]?.keyValue
                              : "Upload doc"
                            : "Upload doc"}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <InterventionMarking
                    interventionDomainDetails={interventionDomainList}
                    domainCategoryId={domainCategoryId}
                    markingOpen={setMarkingOpen}
                    setOpenMarksInfo={setOpenMarksInfo}
                    setOpenInterventionPlan={setOpenInterventionPlan}
                    studentData={studentData}
                    domainMasterId={domainMasterId}
                    subDomainName={subDomainName}
                    setReadPlanData={setReadPlanData}
                  />
                </>
              )}
            </div>
          </>
        )}
        <ToastContainer />
      </div>
      {/* Instruction Modal */}
      <Modal visible={instructionModal}>
        {/* <div className="bg-[#fff] rounded-[30px] py-6 px-8 lg:w-5/12 w-10/12"> */}
        <div className="bg-[#fff] rounded-[30px] lg:w-5/12 w-10/12">
          <div className="flex flex-col items-center">
            <div className="w-full gradient-96 rounded-t-[28px] flex justify-between items-center px-6">
              <h2 className="text-[#ffff] text-[20px] font-semibold my-4">
                {location?.state?.instructions?.defaultName}
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setInstructionModal(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="md"
                  style={{ color: "#3c3f4f" }}
                />
              </p>
            </div>
            <p className="text-[#101942] text-[14px] text-center font-normal my-6 mx-5">
              {location?.state?.instructions?.description &&
                parse(location?.state?.instructions?.description)}
            </p>
          </div>
        </div>
      </Modal>

      {/* View Intervention Uploaded Document Modal */}
      <Modal visible={showInterventionDocModel}>
        {/* <div className="bg-[#fff] rounded-[30px] py-6 px-8 lg:w-5/12 w-10/12"> */}
        <div className="bg-[#fff] rounded-[30px] lg:w-5/12 w-10/12">
          <div className="flex flex-col items-center">
            <div className="w-full gradient-96 rounded-t-[28px] flex justify-between items-center px-6">
              <h2 className="text-[#ffff] text-[20px] font-semibold my-4">
                Intervention Uploaded Document
              </h2>
              <p
                className="bg-[#f6f7fa] rounded-full py-[4px] w-[30px] h-[30px] cursor-pointer"
                onClick={() => setShowInterventionDocModel(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="md"
                  style={{ color: "#3c3f4f" }}
                />
              </p>
            </div>
            <h1>
              <br />
              <br />
            </h1>
            <ul className="flex gap-8">
              {interventionDocument.length > 0 ? (
                interventionDocument.map((doc) => (
                  <li
                    key={doc.id}
                    className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md"
                  >
                    <a
                      href={doc.filePath}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-3 text-[#454545]"
                    >
                      <img
                        src={doc.iconUrl}
                        alt={doc.fileExtension}
                        className="w-10 h-12"
                      />
                      {doc.fileName}
                    </a>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">No Data Found</li>
              )}
            </ul>
            <h1>
              <br />
              <br />{" "}
            </h1>
          </div>
        </div>
      </Modal>

      <ServerUpgradtionModal
        show={serverErrorModal}
        setShow={setServerErrorModal}
        apiCall={interventionDomain}
      />
    </>
  );
};

export default InterventionDomain;
