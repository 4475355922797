import { useState } from "react";
import Avatars from "../../../utils/avatar/Avatars";
import CameraImg from "../../../assets/images/camera.png";
import ReactIcons from "../../../utils/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  blockSelector,
  clearBlockList,
  clearDistrictList,
  clearSchoolList,
  districtSelector,
  genderSelector,
  getBlock,
  getDistrict,
  getGender,
  getSchool,
  getSchoolByUDISE,
  getState,
  schoolSelector,
  stateSelector,
  updateProfile,
} from "../../../redux/slices/profile/profileSlice";
import { useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { indianMobileNumber, crrNumberRegex } from "../../../utils/Regex/regex";
import axios from "axios";
import Modal from "../../modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faXmark,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";
import { decrypt, encrypt } from "../../encryption/Encryption";
import { GetProfession, GetQualification, GetSpecialization } from "./utils";
import ServerUpgradtionModal from "../../ServerUpgradtionModal";
import { ModuleListShimmer } from "../../../utils/certificateShimmer/CourseListShimmer";
import Loader from "../../../utils/loader/Loader";

const Profile = (props) => {
  const [profilePic, setProfilePic] = useState({});
  const [base64String, setBase64String] = useState("");
  const [isProfileReupload, setIsProfileReupload] = useState("true");
  const [showMessage, setShowMessage] = useState(false);
  const [showSchoolName, setShowSchoolName] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const userId = JSON.parse(localStorage.getItem("id"));
  const [UdiseCode, setUdiseCode] = useState("");
  const [schoolByUdise, setSchoolByUdise] = useState();
  const [districtByUdise, setDistrictByUdise] = useState();
  const [stateByUdise, setStateByUdise] = useState();
  const [blockByUdise, setBlockByUdise] = useState();
  const [showButtonText, setShowButtonText] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [changeRequestData, setChangeRequestData] = useState();
  const language_id = localStorage.getItem("l_id");
  const [serverError, setServerError] = useState(false);

  const checkDateFormat = (date) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Test if the date string matches the pattern
    const isMatch = regex.test(date);

    return isMatch;
  };

  const formatDate = (date) => {
    const parts = date?.split("-");

    const dateObj = new Date(`${parts[1]} ${parts[0]}, ${parts[2]}`);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [state, setState] = useState({
    stateId: "",
    districtId: "",
    schoolId: "",
    schoolName: "",
    blockId: "",
    genderId: "",
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    mobileNo: userDetails?.mobileNo ? decrypt(userDetails?.mobileNo) : "",
    whatsAppNo: userDetails?.whatsAppNo ? decrypt(userDetails?.whatsAppNo) : "",
    emailId: userDetails?.emailId ? decrypt(userDetails?.emailId) : "",
    dob: userDetails?.dob
      ? checkDateFormat(decrypt(userDetails?.dob))
        ? decrypt(userDetails?.dob)
        : formatDate(decrypt(userDetails.dob))
      : "",
    profileImage: "",
    userImage: "",
    professionId: [],
    qualificationId: [],
    specializationId: [],
    crrNo: userDetails?.crrNum ? userDetails?.crrNum : "",
  });

  const [professionOptions, setProfessionOtions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  const { firstName, emailId, dob } = state;

  const dispatch = useDispatch();

  const gendersSelector = useSelector(genderSelector);
  const statesSelector = useSelector(stateSelector);
  const districtsSelector = useSelector(districtSelector);
  const schoolsSelector = useSelector(schoolSelector);
  const blocksSelector = useSelector(blockSelector);
  const getTranslateText = useSelector(getTranslatedTextSelector);

  const getValuesForStatesAndGender = () => {
    dispatch(getGender());
    dispatch(getState());
  };

  const isProfileCompleted = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const requiredFields = [
      "dob",
      "gender",
      // "lastName",
      "stateId",
      "userName",
      "firstName",
      "mobileNo",
      "districtId",
      "blockId",
    ];

    if (!user) {
      return false;
    }

    if (user.userTypeId !== 7 || user.userTypeId !== 8) {
      requiredFields.push("schoolId");
    }
    return requiredFields.every((field) => user[field]);
  };

  const handleProfilPic = (e) => {
    const imageFile = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function () {
      setBase64String(reader.result);
      setIsProfileReupload(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    setProfilePic((prev) => ({ ...prev, selectedFile: imageFile }));
  };

  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    if (e.target.name === "school") {
      if (e.target.value === "other") {
        setShowSchoolName(true);
      } else if (e.target.value) {
        setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      state.emailId &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(state.emailId)
    ) {
      setShowMessage(true);
      toast.warn("Email should be in the correct format.");
      return;
    }
    if (
      (userDetails.userTypeId === 7 || userDetails.userTypeId === 8) &&
      state.crrNo &&
      !crrNumberRegex.test(state.crrNo)
    ) {
      setShowMessage(true);
      toast.warn("Invalid crr number.");
      return;
    }
    let formData = new FormData();
    formData.append("UserId", userId);
    formData.append(
      "StateId",
      !stateByUdise ? state.stateId[0]?.value : stateByUdise[0].value
    );
    formData.append(
      "DistrictId",
      !districtByUdise ? state?.districtId[0]?.value : districtByUdise[0].value
    );
    formData.append(
      "SchoolId",
      !schoolByUdise
        ? state?.schoolId[0]?.value === 0
          ? 1
          : state?.schoolId[0]?.value
        : schoolByUdise[0].value
    );
    formData.append(
      "BlockId",
      !blockByUdise ? state.blockId[0]?.value : blockByUdise[0].value
    );
    formData.append("Gender", state.genderId[0]?.value);
    formData.append("FirstName", state.firstName);
    formData.append("LastName", state.lastName ? state.lastName : "");
    formData.append("MobileNo", encrypt(state.mobileNo));
    formData.append("WhatsAppNo", encrypt(state.whatsAppNo));
    formData.append("EmailId", encrypt(emailId));
    formData.append("DOB", encrypt(dob));
    formData.append("ProfileImage", "");
    //formData.append("SchoolName", state.schoolName ? state?.schoolName : "");
    formData.append(
      "SchoolName",
      state?.schoolName
        ? state?.schoolName
        : schoolByUdise
        ? schoolByUdise[0].label
        : ""
    );
    formData.append("QualificationId", state.qualificationId[0]?.value || "");
    formData.append("ProfessionId", state.professionId[0]?.value || "");
    formData.append("SpecializationId", state.specializationId[0]?.value || "");
    formData.append("CRRNum", state.crrNo);

    if (isProfileReupload) {
      formData.append("userImage", profilePic.selectedFile);
    }

    if (error) {
      setShowMessage(true);
      toast.warn("Please enter a valid mobile number");
      return;
    }

    // regex for school name contains alphabet , dot and space in middle
    if (
      state.schoolName &&
      /^(?=.*[a-zA-Z])([1-7-9.,\/ ])+$/.test(state.schoolName)
    ) {
      setShowMessage(true);
      toast.warn("school name should conntains only alphabet");
      return;
    }

    if (isProfileReupload) {
      dispatch(updateProfile(formData))
        .unwrap()
        .then((resp) => {
          const {
            blockId,
            districtId,
            firstName,
            lastName,
            profilePicURL,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            gender,
            mobileNo,
            dob,
            emailId,
            qualificationId,
            professionalId,
            specializationId,
            crrNum,
          } = resp?.result.response[0];
          // qualificationId , professionId , specializationId , crrNum

          const getUser = JSON.parse(localStorage.getItem("user"));
          localStorage.removeItem("user");
          const user = {
            ...getUser,
            blockId: blockId,
            districtId,
            firstName,
            lastName: state.lastName,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            profilePicURL,
            gender,
            mobileNo,
            dob,
            emailId,
            qualificationId,
            professionId: professionalId,
            specializationId,
            crrNum,
          };
          localStorage.setItem("user", JSON.stringify(user));

          if (resp.result.status === 1) {
            setShowMessage(true);
            toast.success("Profile is updated successfully");

            $(".disableSidebar")
              .removeClass("pointer-events-none")
              .addClass("cursor-pointer");
            setTimeout(() => {
              navigate("/user/dashboard", {
                state: { isProfileCompleted: true },
              });
            }, 3000);
          } else {
            setShowMessage(true);
            toast.success("Some Error Occured");
          }
        })
        .catch((error) => {
          setShowMessage(true);
          setServerError(true);
          // toast.warn("Please upload your profile picture");
        });
    }
    setLoading(true);
  };

  useEffect(() => {
    getValuesForStatesAndGender();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (state?.stateId[0]?.value) {
        await dispatch(getDistrict(state?.stateId[0]?.value));
      }
      setLoader(false);
    };
    fetchData();
  }, [state.stateId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (state?.districtId[0]?.value) {
        await dispatch(getBlock(state?.districtId[0]?.value));
      }
      setLoader(false);
    };
    fetchData();
  }, [state.districtId]);

  // useEffect(() => {
  //   if (state.stateId && state.districtId) {
  //     const stateId = state?.stateId[0]?.value;
  //     const districtId = state?.districtId[0]?.value;

  //     dispatch(getSchool({ stateId, districtId }));
  //   }
  // }, [state.districtId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      if (state.blockId) {
        const blockID = state?.blockId[0]?.value;
        if (blockID) {
          await dispatch(getSchool({ blockID }));
        }
      }
      setLoader(false);
    };
    fetchData();
  }, [state.blockId]);

  useEffect(() => {
    if (statesSelector?.data?.length > 0) {
      const { data } = statesSelector;

      const defaultStates = data.filter(
        (item) => item?.value === Number.parseInt(userDetails?.stateId)
      );

      setState((prev) => ({ ...prev, stateId: defaultStates }));
    }
  }, [statesSelector?.data]);

  useEffect(() => {
    if (districtsSelector?.data?.length > 0) {
      const { data } = districtsSelector;

      const defaultDistrict = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.districtId)
      );

      setState((prev) => ({ ...prev, districtId: defaultDistrict }));
    }
  }, [districtsSelector?.data]);

  useEffect(() => {
    if (schoolsSelector?.data?.length > 0) {
      const { data } = schoolsSelector;

      const defaultSchool = data.filter((item) => {
        return item?.value === userDetails?.schoolId;
      });
      if (defaultSchool.length === 0) {
        setState((prev) => ({
          ...prev,
          schoolId: [{ value: 0, label: userDetails?.schoolName }],
        }));
      } else {
        setState((prev) => ({ ...prev, schoolId: defaultSchool }));
      }
    }
  }, [schoolsSelector?.data]);

  useEffect(() => {
    if (gendersSelector?.data?.length > 0) {
      const { data } = gendersSelector;

      const defaultGender = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.gender)
      );

      setState((prev) => ({ ...prev, genderId: defaultGender }));
    }
  }, [gendersSelector?.data]);

  useEffect(() => {
    if (blocksSelector?.data?.length > 0) {
      const { data } = blocksSelector;

      const defaultblock = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.blockId)
      );

      setState((prev) => ({ ...prev, blockId: defaultblock }));
    }
  }, [blocksSelector?.data]);

  const applyUdise = async () => {
    if (!showButtonText) {
      try {
        const response = await dispatch(
          getSchoolByUDISE({ UdiseCode })
        ).unwrap();
        const { status } = response;
        if (status === 1) {
          const { response: apiResponse } = response;

          setSchoolByUdise([
            {
              value: apiResponse[0].schoolId,
              label: apiResponse[0].schoolName,
            },
          ]);
          setDistrictByUdise([
            {
              value: apiResponse[0].districtId,
              label: apiResponse[0].districtName,
            },
          ]);
          setBlockByUdise([
            {
              value: apiResponse[0].blockId,
              label: apiResponse[0].blockName,
            },
          ]);
          setStateByUdise([
            {
              value: apiResponse[0].stateId,
              label: apiResponse[0].stateName,
            },
          ]);
          //setState({ ...state, stateId: [apiResponse[0].stateId], districtId : [apiResponse[0].districtId], blockId: [apiResponse[0].blockId]});
        }
      } catch (error) {}
    } else {
      setStateByUdise();
      setDistrictByUdise();
      setBlockByUdise();
      setSchoolByUdise();
      setUdiseCode("");
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const changeRequest = async (requestId) => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("FilePath", "");
      formData.append("RequestTypeID", requestId);
      formData.append("Priority", "0");
      formData.append("UserID", userId); // Replace with dynamic userId if needed
      formData.append("UpdatedDate", ""); // Adjust accordingly
      formData.append("DocumentTypeId", "0"); // Adjust accordingly
      formData.append("Status", "1");
      formData.append("UpdatedBy", "0");
      formData.append("CreatedDate", new Date().toISOString()); // Current date
      formData.append("AppVersion", "string");
      formData.append("RequestStatusID", "1");
      formData.append("Description", "string"); // Replace with real description
      formData.append("CreatedBy", userId); // Replace with dynamic userId
      formData.append("file", selectedFile); // Attach selected file

      const response = await axios.post(
        `https://api-pi.projectinclusion.in/api/Grievance/NameChangeCreateRequest?languageID=${language_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 && response.data.status === 1) {
        console.log("File uploaded successfully:", response.data);
        setRequestMessage(response.data.response);
        setShowUploadRequestDocModal(false);
        setShowRequestSuccessModal(true);
        checkProfileCompletion();
      } else if (response.data.errorCode === "GRV002") {
        setShowUploadRequestDocModal(false);
        setShowRequestSuccessModal(false);
        toast.error(response.data.message);
      } else {
        setShowUploadRequestDocModal(false);
        toast.error("Request creation failed..!");
      }
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getProfession = async () => {
    const response = await GetProfession();

    const data = response.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));

    if (userDetails.professionId) {
      const filterProfession = data.filter(
        (item) => item.value === +userDetails.professionId
      );

      setState((prevValues) => ({
        ...prevValues,
        professionId: filterProfession,
      }));
    }

    setProfessionOtions(data);
  };

  const getQualification = async () => {
    const response = await GetQualification(state?.professionId[0]?.value);
    const data = response.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));

    if (userDetails.qualificationId) {
      const filterQualification = data.filter(
        (item) => item.value === +userDetails.qualificationId
      );

      setState((prevValues) => ({
        ...prevValues,
        qualificationId: filterQualification,
      }));
    }

    setQualificationOptions(data);
  };

  const getSpecialization = async () => {
    const response = await GetSpecialization(
      state?.professionId[0]?.value,
      state?.qualificationId[0]?.value
    );

    const data = response.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));

    if (userDetails.specializationId) {
      const filterSpecialization = data.filter(
        (item) => item.value === +userDetails.specializationId
      );

      setState((prevValues) => ({
        ...prevValues,
        specializationId: filterSpecialization,
      }));
    }

    setSpecializationOptions(data);
  };

  const [showModal, setShowModal] = useState(false);

  const [showChangeRequestModal, setShowChangeRequestModal] = useState(false);
  const [showUploadRequestDocModal, setShowUploadRequestDocModal] =
    useState(false);
  const [showRequestSuccessModal, setShowRequestSuccessModal] = useState(false);
  const [showReqAlreadySubmitModel, setShowReqAlreadySubmitModel] =
    useState(false);
  const [previewImage, setPreviewImage] = useState(null); // State for previewing image
  const [selectedFile, setSelectedFile] = useState(null);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestTypeId, setRequestTypeId] = useState(0);

  const [shimmer, setShimmer] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      console.log("Selected file:", file); // Debugging log
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          console.log("File preview URL:", reader.result); // Debugging log
          setPreviewImage(reader.result); // Set the image preview
        };
        reader.readAsDataURL(file); // Read the file as data URL for preview
      } else {
        console.log("Not an image file.");
        setPreviewImage(null); // Reset preview if not an image
      }
    } else {
      console.log("No file selected.");
      setPreviewImage(null); // Reset preview when no file is selected
    }
  };

  const onClickChangeRequest = (requestTypeId) => {
    switch (requestTypeId) {
      case 1:
        if (checkProfileCompletionData?.grievanceNameChangeRequest === null) {
          setShowChangeRequestModal(true);
        } else {
          setShowReqAlreadySubmitModel(true);
        }
        setRequestTypeId(1);
        break;

      case 2:
        if (checkProfileCompletionData?.grievanceSchoolChangeRequest === null) {
          setShowChangeRequestModal(true);
        } else {
          setShowReqAlreadySubmitModel(true);
        }
        setRequestTypeId(2);
        break;

      default:
        // You can handle other requestTypeId cases here if needed.
        break;
    }
  };

  const [checkProfileCompletionData, setCheckProfileCompletionData] = useState(
    []
  );

  const checkProfileCompletion = async () => {
    //setShimmer(true);
    const response = await axios.post(
      `https://api-pi.projectinclusion.in/api/Login/CheckProfileCompletion?UserID=${userId}`
    );
    setCheckProfileCompletionData(response?.data?.response);
    //setShimmer(false);
    return response?.data?.response;
  };

  const handleKeyboardEvent = (e) => {
    if (firstName?.trim() === "" && e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getProfession();
  }, []);

  useEffect(() => {
    checkProfileCompletion();
  }, []);

  useEffect(() => {
    if (state.professionId.length > 0) {
      getQualification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.professionId]);

  useEffect(() => {
    if (state.qualificationId.length > 0) {
      getSpecialization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.qualificationId]);

  useEffect(() => {
    setTimeout(() => {
      setShimmer(false);
    }, 4000);
  }, []);

  return (
    <>
      <div className="bg-gradient-to-b from-[#002EFF2B] to-[#0DE9FF2B] drop-shadow-[0_04px_20px_#00000021] grid grid-cols-1 md:grid-cols-6 md:gap-1 p-[20px] md:max-h-[100vh] h-[calc(100%_-_6%)] lg:h-[calc(100%_-_9%)] overflow-y-scroll">
        <div className="col-span-2 bg-[#ffffffad] rounded-md md:mr-[15px] mb-5 md:mb-0 backdrop-blur-md">
          <div className="flex mt-5 ml-5">
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ color: "#000000" }}
              size="xl"
              onClick={() => navigate(-1)}
              className="mr-5 cursor-pointer"
            />
          </div>
          <div className="my-[20px] relative @mx-[60px]">
            <input
              accept="image/*"
              id="profile-picture"
              type={"file"}
              className="hidden"
              onChange={handleProfilPic}
            />
            <Avatars
              round={true}
              size={160}
              src={
                base64String ? base64String : userDetails?.profilePicURL
                // ? userDetails?.profilePicURL
                // : profileIcon
              }
            />
            {/* <div className="border-[#CACEE2] border-[1px] rounded-[100%] bg-[#fff] ps-[20px] flex justify-center">
              <img
                // src={profileIcon}
                src={base64String ? base64String : userDetails?.profilePicURL}
                className=""
              />
            </div> */}
            <label
              htmlFor="profile-picture"
              className="absolute right-[20%] xl:right-[60px] lg:right-[0px] md:top-[-2px] w-[30%]"
            >
              <img
                src={CameraImg}
                width="50%"
                className="text-[#ffffffad] cursor-pointer "
                alt=""
              />
            </label>
          </div>
          <div className="flex flex-row flex-wrap justify-evenly profile-form m-[50px]">
            <div className="basis-full flex flex-col text-left">
              <label htmlFor="username" className="mb-2 text-lg">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "field_username"
                    )[0]?.keyValue
                  : "Username"}
              </label>
              <input
                id="username"
                type="text"
                className="bg-[#F2F8FB]"
                placeholder="Enter Name"
                value={decrypt(userDetails?.userName)}
                readOnly
              />
            </div>
            <div className="basis-full flex flex-col text-left">
              <label htmlFor="emailid" className="my-2 text-lg">
                {getTranslateText?.data?.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item?.pageKey === "field_email"
                    )[0]?.keyValue
                  : "Email Id"}
              </label>
              <input
                id="emailid"
                name="emailId"
                type="email"
                className="bg-[#F2F8FB]"
                placeholder={
                  getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "field_email"
                      )[0]?.keyValue
                    : "Email Id"
                }
                value={state?.emailId}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="col-span-4 bg-[#fff] rounded-md">
          <div className="flex flex-row justify-between items-center my-[21px] mx-[54px] ">
            <h1 className="text-[24px] text-left">
              {isProfileCompleted() === true ? (
                <>
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "field_edit_profile"
                      )[0]?.keyValue
                    : "Create Profile"}
                </>
              ) : (
                <>
                  {getTranslateText?.data?.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item?.pageKey === "field_edit_profile"
                      )[0]?.keyValue
                    : "Create Profile"}
                </>
              )}
            </h1>
            {!isProfileCompleted() ? (
              <p
                className="cursor-pointer text-red-600"
                onClick={() => {
                  localStorage.clear();
                  navigate("/select/language");
                }}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  size="lg"
                />
              </p>
            ) : (
              <></>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            {shimmer ? (
              <>
                <ModuleListShimmer />
                <ModuleListShimmer />
                <ModuleListShimmer />
                <ModuleListShimmer />
                <ModuleListShimmer />
              </>
            ) : loader ? (
              <>
                <div
                  className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-opacity-0.5"
                  style={{ backgroundColor: "#D3D3D3" }}
                >
                  <Loader type="spinningBubbles" color="#2e376c" />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row flex-wrap	justify-evenly profile-form">
                  <div className="basis-4/5 md:basis-2/5	flex flex-col text-left">
                    <div className="flex flex-row justify-between w-[100%]">
                      <label>
                        {getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "field_fname_mname"
                            )[0]?.keyValue
                          : "First & Middle Name "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {isProfileCompleted() === true ? (
                        <>
                          <p
                            className={` ${
                              userDetails?.userTypeId === 9
                                ? "hidden"
                                : "cursor-pointer"
                            } text-[13px] text-[#2C519A] underlines font-medium`}
                            // onClick={() => {
                            //   changeRequest(1);
                            // }}
                            onClick={() => {
                              //setShowModal(true);
                              //setRequestTypeId(1);
                              onClickChangeRequest(1);
                              //changeRequest(language_id, 1);
                            }}
                          >
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_change"
                                )[0].keyValue
                              : "Change"}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <input
                      name="firstName"
                      className={`${
                        isProfileCompleted() === true
                          ? "bg-[#eee] pointer-events-none text-[#000000a8]"
                          : ""
                      } bg-[#F2F8FB]`}
                      type={"text"}
                      // value={}
                      maxLength={30}
                      minLength={2}
                      placeholder={
                        getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "field_fname_mname"
                            )[0]?.keyValue
                          : "First & Middle Name"
                      }
                      onChange={(e) => {
                        // setFullName(e.target.value);

                        // const result = e.target.value.replace(/[^a-z]/gi, "");
                        setState({ ...state, firstName: e.target.value });
                      }}
                      // onChange={(e) => {
                      //   setFullName(e.target.value);
                      //   setState({ ...state, firstName: result });
                      // }}
                      onKeyDown={handleKeyboardEvent}
                      required
                      value={state?.firstName}
                    />
                    {/* <p>Hints</p> */}
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <div className="flex flex-row justify-between w-[80%]">
                      <label>
                        {getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "field_lname"
                            )[0]?.keyValue
                          : "Last Name"}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                      {isProfileCompleted() === true ? (
                        <>
                          <p
                            className="text-[13px] text-[#2C519A] underlines font-medium cursor-pointer hidden"
                            onClick={() => {
                              setShowModal(true);
                              changeRequest(language_id, 1);
                            }}
                          >
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item.pageKey === "label_change"
                                )[0].keyValue
                              : "Change"}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <input
                      name="lastName"
                      type={"text"}
                      maxLength={30}
                      minLength={" "}
                      className={`${
                        isProfileCompleted() === true
                          ? "bg-[#eee] pointer-events-none text-[#000000a8]"
                          : ""
                      } bg-[#F2F8FB]`}
                      // className="bg-[#f2f8fb] text-[#000000a8]"
                      placeholder={
                        getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "field_lname"
                            )[0]?.keyValue
                          : "Last Name"
                      }
                      onChange={(e) => {
                        const result = e.target.value.replace(/[^a-z]/gi, "");
                        setState({ ...state, lastName: result });
                      }}
                      value={state?.lastName}
                      // value=""
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	flex flex-col">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_dob"
                          )[0]?.keyValue
                        : " DOB"}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"date"}
                      className="bg-[#F2F8FB]"
                      name="dob"
                      required
                      max={currentDate}
                      title="Date of birth should be greater then 18 years"
                      value={state?.dob}
                      onChange={(e) => {
                        const enteredDate = new Date(e.target.value);
                        const currentDate = new Date();
                        const minDate = new Date(
                          currentDate.getFullYear() - 18,
                          currentDate.getMonth(),
                          currentDate.getDate()
                        );

                        if (enteredDate > minDate) {
                          // DOB is less than 18 years
                          //  setShowDobErrorMessage(true);
                        } else {
                          // DOB is greater than or equal to 18 years
                          //  setShowDobErrorMessage(false);
                          handleChange(e);
                        }
                        // handleChange(e);
                      }}
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_gender"
                          )[0]?.keyValue
                        : "Gender"}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={gendersSelector?.data}
                      value={state?.genderId}
                      required
                      onChange={(selectedOption) => {
                        setState({ ...state, genderId: [selectedOption] });
                      }}
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_phone_number"
                          )[0]?.keyValue
                        : "Phone Number"}
                      {/* Phone Number */}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      name="mobileNo"
                      className="bg-[#F2F8FB]"
                      placeholder="Enter phone number"
                      // value={decrypt(state?.mobileNo)}
                      value={
                        localStorage.getItem("mobileno")
                          ? localStorage.getItem("mobileno")
                          : state?.mobileNo
                      }
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      readOnly
                      minLength={10}
                      maxLength={10}
                      required
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_whatsapp_number"
                          )[0]?.keyValue
                        : " Whatsapp Number"}
                    </label>
                    <input
                      type={"text"}
                      name="whatsAppNo"
                      className="bg-[#F2F8FB]"
                      placeholder={
                        getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) =>
                                item?.pageKey === "field_whatsapp_number"
                            )[0]?.keyValue
                          : " Whatsapp Number"
                      }
                      value={state.whatsAppNo}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError(false);
                          setState({ ...state, whatsAppNo: "" });
                        } else if (!indianMobileNumber.test(e.target.value)) {
                          setError(true);
                          setState({ ...state, whatsAppNo: e.target.value });
                        } else {
                          setError(false);
                          setState({ ...state, whatsAppNo: e.target.value });
                        }
                      }}
                      minLength={10}
                      maxLength={10}
                    />
                    {error && (
                      <p style={{ color: "red" }}>Invalid mobile number</p>
                    )}
                  </div>

                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "label_udise"
                          )[0]?.keyValue
                        : "School Udise Name"}
                    </label>
                    <div className="!flex !flex-row justify-between md:justify-start w-[100%]">
                      <input
                        type={"text"}
                        className="bg-[#F2F8FB] w-[80%] md:!w-[95%] mr-2"
                        placeholder={
                          getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "hint_udise"
                              )[0]?.keyValue
                            : "Enter UDISE Code"
                        }
                        value={UdiseCode}
                        maxLength={11}
                        disabled={isProfileCompleted()}
                        title={
                          isProfileCompleted()
                            ? "Click on change button for school updation..!"
                            : ""
                        }
                        onChange={(e) => {
                          const input = e.target.value;
                          //if (!/^\d+$/.test(input)) {
                          if (!/^[a-zA-Z0-9]*$/.test(input)) {
                            e.preventDefault();
                            if (UdiseCode.length === 1) {
                              // handleChange(e);
                              setUdiseCode(e.target.value);
                            }
                          } else {
                            setUdiseCode(e.target.value);
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="bg-gradient-to-b from-[#B0BDFF] to-[#5E77F9] text-white rounded-md px-3 text-sm"
                        style={{ textWrap: "nowrap" }}
                        disabled={!UdiseCode || isProfileCompleted()}
                        onClick={(e) => {
                          applyUdise();
                          setShowButtonText(!showButtonText);
                        }}
                      >
                        {showButtonText
                          ? getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "reset_btn"
                              )[0]?.keyValue
                            : "Reset"
                          : getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "label_okay"
                            )[0]?.keyValue
                          : "Apply"}
                      </button>
                    </div>
                  </div>

                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_state"
                          )[0]?.keyValue
                        : "State"}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={statesSelector?.data}
                      value={!stateByUdise ? state.stateId : stateByUdise}
                      required
                      onChange={(selectedOption) => {
                        dispatch(clearDistrictList());
                        dispatch(clearBlockList());
                        dispatch(clearSchoolList());
                        if (!isProfileCompleted()) {
                          setState({
                            ...state,
                            stateId: [selectedOption],
                            blockId: "",
                            schoolId: "",
                          });
                        } else {
                          setState({
                            ...state,
                            stateId: [selectedOption],
                            blockId: "",
                          });
                        }
                      }}
                      isDisabled={showButtonText}
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_district"
                          )[0]?.keyValue
                        : "District"}
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <Select
                      options={districtsSelector?.data}
                      value={
                        !districtByUdise ? state?.districtId : districtByUdise
                      }
                      required
                      onChange={(selectedOption) => {
                        dispatch(clearBlockList());
                        dispatch(clearSchoolList());
                        if (!isProfileCompleted()) {
                          setState({
                            ...state,
                            districtId: [selectedOption],
                            schoolId: "",
                          });
                        } else {
                          setState({
                            ...state,
                            districtId: [selectedOption],
                          });
                        }
                      }}
                      isDisabled={showButtonText}
                    />
                  </div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <label>
                      {getTranslateText?.data?.length > 0
                        ? getTranslateText?.data?.filter(
                            (item) => item?.pageKey === "field_block"
                          )[0]?.keyValue
                        : "Block/Zone"}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      options={blocksSelector?.data}
                      value={!blockByUdise ? state?.blockId : blockByUdise}
                      required
                      onChange={(selectedOption) => {
                        dispatch(clearSchoolList());
                        if (!isProfileCompleted()) {
                          setState({
                            ...state,
                            blockId: [selectedOption],
                            schoolId: "",
                          });
                        } else {
                          setState({
                            ...state,
                            blockId: [selectedOption],
                          });
                        }
                      }}
                      isDisabled={showButtonText}
                    />
                  </div>

                  <div className="basis-4/5 md:basis-2/5	">
                    <div className="flex flex-row justify-between w-[100%]">
                      <label>
                        {getTranslateText?.data?.length > 0
                          ? getTranslateText?.data?.filter(
                              (item) => item?.pageKey === "field_school"
                            )[0]?.keyValue
                          : "School"}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {isProfileCompleted() === true ? (
                        <>
                          <p
                            className={`${
                              userDetails?.userTypeId === 9
                                ? "hidden"
                                : "cursor-pointer"
                            } text-[13px] text-[#2C519A] underlines font-medium`}
                            onClick={() => {
                              // setShowModal(true);
                              // changeRequest(language_id, 2);
                              //setRequestTypeId(2);
                              onClickChangeRequest(2);
                            }}
                          >
                            {getTranslateText?.data?.length > 0
                              ? getTranslateText?.data?.filter(
                                  (item) => item?.pageKey === "label_change"
                                )[0]?.keyValue
                              : "Change"}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <Select
                      className={`${
                        isProfileCompleted() === true
                          ? "[&_.css-13cymwt-control]:bg-[#eee] pointer-events-none text-[#000000a8]"
                          : ""
                      } bg-[#F2F8FB]`}
                      options={schoolsSelector?.data}
                      value={!schoolByUdise ? state?.schoolId : schoolByUdise}
                      required
                      onChange={(selectedOption) => {
                        if (selectedOption.value === 0) {
                          // setState({
                          //   ...state,
                          //   schoolId: [{ value: 0, label: "other" }],
                          // });
                          setState({
                            ...state,
                            schoolId: [selectedOption],
                          });
                          setShowSchoolName(true);
                        } else {
                          setShowSchoolName(false);
                          setState({
                            ...state,
                            schoolId: [selectedOption],
                          });
                        }
                      }}
                      isDisabled={showButtonText}
                    />
                    {showSchoolName && (
                      <input
                        type={"text"}
                        name="schoolName"
                        className="bg-[#F2F8FB] mr-2 mt-2"
                        required
                        placeholder="Enter school name"
                        maxLength={150}
                        onChange={(e) => {
                          setState({
                            ...state,
                            schoolName: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>

                  {userDetails.userTypeId === 7 ||
                  userDetails.userTypeId === 8 ? (
                    <>
                      <div className="basis-4/5 md:basis-2/5	">
                        <label>
                          {/* Profession */}
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "key_profession"
                              )[0]?.keyValue
                            : "Profession"}
                        </label>
                        <Select
                          options={professionOptions}
                          value={state?.professionId[0]}
                          placeholder="Please select profession"
                          onChange={(selectedOption) => {
                            setState({
                              ...state,
                              professionId: [selectedOption],
                              specializationId: [],
                              qualificationId: [],
                            });
                          }}
                        />
                      </div>
                      <div className="basis-4/5 md:basis-2/5	">
                        <label>
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "key_qualification"
                              )[0]?.keyValue
                            : "Qualification"}
                        </label>
                        <Select
                          options={qualificationOptions}
                          // required
                          placeholder="Please select qualification"
                          value={state?.qualificationId[0]}
                          onChange={(selectedOption) => {
                            setState({
                              ...state,
                              qualificationId: [selectedOption],
                            });
                          }}
                        />
                      </div>
                      <div className="basis-4/5 md:basis-2/5	">
                        <label>
                          {getTranslateText?.data?.length > 0
                            ? getTranslateText?.data?.filter(
                                (item) => item?.pageKey === "key_specialization"
                              )[0]?.keyValue
                            : "Specialization"}
                        </label>
                        <Select
                          placeholder="Please select profession"
                          value={state?.specializationId[[0]]}
                          // required
                          options={specializationOptions}
                          onChange={(selectedOption) => {
                            setState({
                              ...state,
                              specializationId: [selectedOption],
                            });
                          }}
                        />
                      </div>
                      <div className="basis-4/5 md:basis-2/5	">
                        <label>CRR No</label>
                        <input
                          type={"text"}
                          value={state.crrNo}
                          maxLength={7}
                          className="bg-[#F2F8FB] w-[80%] md:!w-[95%] mr-2"
                          placeholder="CRR NO"
                          onChange={(e) => {
                            setState({ ...state, crrNo: e.target.value });
                          }}
                        />
                        {state.crrNo && !crrNumberRegex.test(state.crrNo) ? (
                          <label style={{ color: "red" }}>
                            Invalid crr number
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="basis-4/5 md:basis-2/5	"></div>
                  <div className="basis-4/5 md:basis-2/5	">
                    <button
                      type="submit"
                      className={`${
                        userDetails?.userTypeId === 9 ? "hidden" : ""
                      }${
                        loading
                          ? "bg-[#3a3e58] text-[#fff] py-3 px-[40px] xl:px-20 lg:px-14 rounded-md mt-5 flex relative m-auto md:m-1 px-[40px]"
                          : "bg-[#101942] text-[#fff] py-3 px-[40px] xl:px-20 lg:px-14 rounded-md mt-5 flex relative m-auto md:m-1 px-[40px]"
                      }`}
                    >
                      {loading ? (
                        <>
                          <div className="flex items-center space-x-2">
                            <div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full"></div>
                            <span>Loading...</span>
                          </div>
                        </>
                      ) : getTranslateText?.data?.length > 0 ? (
                        getTranslateText?.data?.filter(
                          (item) => item?.pageKey === "btn_proceed"
                        )[0]?.keyValue
                      ) : (
                        "Proceed"
                      )}
                      <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#fff] text-xl" />
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>

        {showMessage && <ToastContainer />}
      </div>

      {/* commented on 2024-10-09 'This popup is used to show request created and request already created message' */}
      {/* <Modal
        onClick={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
        visible={showModal}
      >
        <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          <img
            src="../../Images/puzzle_smile.svg"
            alt="Puzzle Smiley Icon"
            className="w-[30%] m-auto"
          />
          <h2 className="text-[20px] my-2">
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item?.pageKey === "key_request_name"
                )[0]?.keyValue
              : "Request Created"}
          </h2>
          <p className="text-[14px]">
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item?.pageKey === "request_already_registered"
                )[0]?.keyValue
              : { changeRequestData }}
          </p>
          ();
          <p
            className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            {getTranslateText?.data?.length > 0
              ? getTranslateText?.data?.filter(
                  (item) => item?.pageKey === "label_okay"
                )[0]?.keyValue
              : "Okay"}
          </p>
        </div>
      </Modal> */}

      {/* View Name change request required Document list Modal */}
      <Modal visible={showChangeRequestModal}>
        <div className="relative w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          {/* Close icon inside the modal content, adjusted position */}
          <p
            className="absolute top-4 right-4 bg-[#f6f7fa] rounded-full w-[30px] h-[30px] flex justify-center items-center cursor-pointer z-10"
            onClick={() => setShowChangeRequestModal(false)}
          >
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              style={{ color: "#3c3f4f" }}
            />
          </p>

          {/* Centered Image */}
          <img
            src="../../Images/user-profile-thumbnail.png"
            alt="Puzzle Smiley Icon"
            className="w-[30%] m-auto my-0"
          />

          <h2 className="text-[17px] my-2 text-[#101942]">
            <b>
              {" "}
              {requestTypeId === 1
                ? "Please upload a valid ID proof for the Name change request"
                : "Please upload a valid ID proof for the School name change request"}{" "}
            </b>
          </h2>

          <h6 className="text-[14px] text-[#565D7A]">
            Please upload one of these IDs-
          </h6>
          <br />
          {requestTypeId === 1 ? (
            <ul className="text-[15px] text-[#565D7A] text-left">
              <li className="p-[8px]">1. Aadhaar Card (front only) </li>
              <li className="p-[8px]">2. Pan Card (front only) </li>
              <li className="p-[8px]">3. Voter ID (front only) </li>
              <li className="p-[8px]">4. Driving Licence (front only) </li>
            </ul>
          ) : (
            <ul className="text-[15px] text-[#565D7A] text-left">
              <li className="p-[8px]">1. School ID Card (front only) </li>
              <li className="p-[8px]">
                2. Authorization Letter from the school{" "}
              </li>
            </ul>
          )}

          <p
            className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px] flex flex-row justify-center cursor-pointer"
            onClick={() => {
              setShowChangeRequestModal(false);
              setShowUploadRequestDocModal(true);
            }}
          >
            Continue
          </p>
        </div>
      </Modal>

      {/* Upload Document and preview Modal */}
      <Modal visible={showUploadRequestDocModal}>
        <div className="relative w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          <p
            className="absolute top-4 right-4 bg-[#f6f7fa] rounded-full w-[30px] h-[30px] flex justify-center items-center cursor-pointer z-10"
            onClick={() => setShowUploadRequestDocModal(false)}
          >
            <FontAwesomeIcon
              icon={faXmark}
              size="md"
              style={{ color: "#3c3f4f" }}
            />
          </p>

          <h2 className="text-[17px] my-4 text-[#101942] text-center">
            <b> Upload Document </b>
          </h2>

          <h6 className="text-[14px] text-[#565D7A]">
            Please upload a clear copy of your id proof, only front side for
            name varifaction.
          </h6>
          <br />
          {/* Large Upload Area */}
          <label className="w-full h-[200px] border-2 border-dashed border-[#F9B239] rounded-[10px] flex flex-col items-center justify-center cursor-pointer">
            <input
              type="file"
              className="hidden"
              onChange={handleFileChange} // Add this if you want to handle file input change
            />
            {/* If previewImage exists, display it */}
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                className="w-full h-full object-contain rounded-[10px]"
              />
            ) : (
              <>
                <p className="text-[#F9B239] font-semibold text-center">
                  Please choose and upload the front side image
                </p>
                <p className="text-[#565D7A] text-center text-[14px]">
                  (Select image and maximum size: 10MB)
                </p>
              </>
            )}
          </label>

          <p
            className={
              selectedFile === null || selectedFile === ""
                ? "bg-gradient-to-r from-[#2235A1] to-[#121F55] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer bg-gradient-to-r from-[#3a3e58] to-[#777f94]"
                : "bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
            }
            onClick={() => changeRequest(requestTypeId)}
          >
            Upload
          </p>
        </div>
      </Modal>

      {/* Name change request submit success model */}
      <Modal visible={showRequestSuccessModal}>
        <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          <img
            src="../../Images/success-icon.png"
            alt="Puzzle Smiley Icon"
            className="w-[30%] m-auto"
          />
          <h2 className="text-[17px] my-2 text-[#101942]">
            <b> Document Received! </b>
          </h2>

          <h6 className="text-[14px] text-[#565D7A]">{requestMessage}</h6>
          <br />

          <p
            className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
            onClick={() => setShowRequestSuccessModal(false)}
          >
            okay
          </p>
        </div>
      </Modal>

      {/* Name change request already submitted Modal */}
      <Modal visible={showReqAlreadySubmitModel}>
        <div className="w-96 bg-[#fff] p-[20px] rounded-[20px] flex flex-col justify-center">
          <h2 className="text-[17px] my-2 text-[#101942]">
            <b>
              {" "}
              {requestTypeId === 1
                ? "Name change request already submitted!"
                : "School name change request already submitted!"}{" "}
            </b>
          </h2>

          <h6 className="text-[14px] text-[#565D7A]">
            {requestTypeId === 1
              ? checkProfileCompletionData?.grievanceNameChangeRequest?.response
              : checkProfileCompletionData?.grievanceSchoolChangeRequest
                  ?.response}
          </h6>
          <br />

          <p
            className="bg-gradient-to-r from-[#2C3EA2] to-[#192867] text-[#fff] px-[50px] py-[10px] rounded-[10px] mt-[20px]  flex flex-row justify-center cursor-pointer"
            onClick={() => setShowReqAlreadySubmitModel(false)}
          >
            okay
          </p>
        </div>
      </Modal>

      <ServerUpgradtionModal
        show={serverError}
        setShow={setServerError}
        apiCall={handleSubmit}
      />
    </>
  );
};

export default Profile;
