import React, { useEffect, useState } from "react";
import { getInterventionPlan } from "./InterventionApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../utils/loader/Loader";

const InterventionPlan = (props) => {
  const { setOpenInterventionPlan, studentData, readPlanData } = props;
  const [interventionPlanDetails, setInterventionPlanDetails] = useState([]);
  const parse = require("html-react-parser");

  const [loader, setLoader] = useState(false);

  const interventionPlan = () => {
    setLoader(true);
    const domainId = readPlanData?.domainMasterID;
    const gradeId = studentData?.gradeId;
    const classroomObservationID = readPlanData?.classroomObservationID;
    getInterventionPlan(domainId, gradeId, classroomObservationID).then(
      (response) => {
        setInterventionPlanDetails(response.data.response[0]);
        if(response?.data?.response.length>0){
          setLoader(false);
        }
        else{setLoader(true);}
      }
    );
  };

  useEffect(() => {
    interventionPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loader === false ? (
        <div className="bg-[#F4F4FA] h-full">
          <div className="w-4/4 m-auto">
            <div className="gradient-96 rounded-t-[30px] flex justify-start items-center py-4 px-6">
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ color: "#fff" }}
                size="xl"
                onClick={() => {
                  // navigate(-1);
                  // setOpenMarksInfo(false);
                  setOpenInterventionPlan(false);
                }}
                className="mr-2 cursor-pointer"
              />
              <h2 className="font-semibold text-[22px] text-[#fff]">
                Intervention Plan
              </h2>
            </div>
            <div className="bg-[#fff] rounded-b-[30px] text-left px-6 py-4">
              <div className="mb-4">
                <h3 className="text-[#101942] font-semibold text-[16px]">
                  Concern
                </h3>
                <p className="text-[#101942] text-[14px] font-regular">
                  {interventionPlanDetails?.concern &&
                    parse(interventionPlanDetails?.concern)}
                </p>
              </div>
              <div className="mb-4">
                <h3 className="text-[#101942] font-semibold text-[16px]">
                  Goal
                </h3>
                <p className="text-[#101942] text-[14px] font-regular">
                  {interventionPlanDetails?.goal &&
                    parse(interventionPlanDetails?.goal)}
                </p>
              </div>
              <div className="mb-4">
                <h3 className="text-[#101942] font-semibold text-[16px]">
                  {/* Adaptation (Flexibility in curriculum) */}
                  Accommodation ( Flexibility in Classroom)
                </h3>
                <p className="text-[#101942] text-[14px] font-regular">
                  {interventionPlanDetails?.adaptation &&
                    parse(interventionPlanDetails?.adaptation)}
                </p>
              </div>
              <div className="mb-4">
                <h3 className="text-[#101942] font-semibold text-[16px]">
                  Steps to teach
                </h3>
                <p className="text-[#101942] text-[14px] font-regular">
                  {interventionPlanDetails?.stepToTeach &&
                    parse(interventionPlanDetails?.stepToTeach)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center h-[80%]">
            <Loader type="spinningBubbles" color="#2e376c" />
          </div>
        </>
      )}
    </>
  );
};

export default InterventionPlan;
