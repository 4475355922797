export const apiEndPoints = {
  USER_TYPE: "/UserType/GetAppUserType",
  // USER_TYPE: "/UserType",
  GET_LANGUAGE: "/Language",
  VALIDATE_USER: "/Login/ValidateUser",
  SEND_OTP: "/Login/SendOTP",
  VERIFY_OTP: "/Login/VerifyOTP",
  LOGIN: "/Login/UserLogin",
  GET_TOKEN: "/Login/UserAuthentication",
  LOGIN_WITH_OTP: "Login/LoginwithOTPs",
  REGISTER: "/Login/UserRegistration",
  UPDATE_PROFILE: "/Login/UpdateUsersProfile",
  UPDATE_PROFILE_NO_IMAGE: "/Login/UpdateUsersProfileNoImage",
  GET_GENDER: "/Gender",
  GET_GRADE: "/Grade/GetActive",
  GET_STATES: "/State",
  GET_BLOCK: "/Block/GetBlockByDistrict",
  GET_DISTRICT_BY_STATE_ID: "/District/GetDistrictByState",
  GET_SCHOOL_BY_DISTRICT_ID: "/School/GetSchools",
  FORGOT_PASSWORD: "/Login/SetPassword",
  GET_LMS_URL: "/LMSConfiguration/GetActiveURL",
  GET_APPLICATION_TEXT:
    "/ApplicationTranslatedText/GetApplicationTextByLanguage",
  GET_SCHOOL_BY_UDISE: "/School/GetSchoolByUdiseCode",
  GET_CERTIFICATE_TYPE: "/CertificateType",
  GET_OCCUPATION: "Occupation/GetActive",
  GET_BOARD: "SchoolBoard/GetActive",
  GET_SCHOOL_TYPE: "SchoolType/GetActive",
  GET_EDUCATIONALSTATUS: "EducationType/GetActive",
  DACTIVATE_USER: "Login/DeactivateUser",
  ACTIVATE_USER: "Login/RecoverAccount",
  GET_PROFESSION: '/Profession/GetProfession',
  GET_QUALIFICATION: '/Profession/GetQualification',
  GET_SPECIALIZATION: '/Profession/GetSpecialization',
  GET_SCHOOL_BY_UDISE_SchoolApi: '/SchoolMaster/GetSchoolByUdiseCode',
  GET_SCHOOL_BY_BlockId_SchoolApi: '/SchoolMaster/GetSchoolsByBlockId',
};
